import bendingServiecImg from "./img/banner/bendingServicw.jpg";
import semicon2024 from "./img/banner/semicon2024.jpg";

export const bannerItem = [
  {
    title: "bending_service",
    img: bendingServiecImg,
    link: "/semi/parts/cprobe",
  },
  // {
  //   title: "semicon_taiwan_2024",
  //   img: semicon2024,
  //   link: "https://www.mempro.co.kr/semiconMap.png",
  // },
];
